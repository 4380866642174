import axios from "axios";
import * as ls from "local-storage";
import { ref, computed } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import router from "@/router";
import constants from "@/utils/constants";
import { make } from "@/utils/request";
import { replaceNonWordChars } from "@/utils/helpers";

// import { useCompanies } from "./companies";
import { useCompanyInvites } from "./companies-invites";
import { useAppConfig } from "./app-config";
// import { useCompanyLocations } from "./companies-locations";
// import { useCompanyEmployees } from "./companies-employees";
// import { useCompanyAudiences } from "./companies-audiences";
// import { useChat } from "./chat";
// import { useAppConfig } from "./app-config";

const createAuthInterceptor = (authToken) => {
  if (!authToken) {
    return null;
  }

  return axios.interceptors.request.use(
    (requestConfig) => ({
      ...requestConfig,
      headers: { ...requestConfig.headers, Authorization: `Bearer ${authToken}` },
    }),
    (error) => {
      return Promise.reject(error);
    }
  );
};

const initialTokenValue = ls.get(constants.AUTH_TOKEN_KEY);

if (initialTokenValue) {
  createAuthInterceptor(initialTokenValue);
}

export const useAuth = defineStore("auth", () => {
  const inviteStore = useCompanyInvites();
  const { companyInvites } = storeToRefs(inviteStore);
  const appConfigStore = useAppConfig();
  const { setShowAppBanner } = appConfigStore;

  const user = ref(null);
  const authToken = ref(initialTokenValue ?? null);
  const isAuthenticating = ref(false);
  const minAppVersion = ref(null);
  const isCheckedForAuth = ref(false);

  const isLoggedIn = computed(() => Boolean(authToken.value));

  const fetchCompanyInvites = async () => {
    if (!user.value) {
      return [];
    }

    const companyInvites = ref([]);

    if (user.value?.phone) {
      const invites = await make({ name: "getTeamInvites", params: { phone: user.value.phone } });
      companyInvites.value = invites;

      return invites;
    } else {
      console.warn("User doesnt have an associated phone number can't fetch invites.");
      return [];
    }
  };

  const acceptCompanyInvite = async (inviteCode) => {
    // TODO: what does this return? Do we have to refetch?
    const response = await make({ name: "acceptInvitation", data: { token: inviteCode } });

    return response;
  };

  const ignoreCompanyInvite = async (inviteCode) => {
    // TODO: what does this return? Do we have to refetch?
    const response = await make({ name: "ignoreInvitation", data: { token: inviteCode } });

    return response;
  };

  const defaultLogin = async ({ email, password, token }) => {
    const newToken = await make({
      name: "login",
      data: {
        email,
        password,
        token,
      },
    });

    authToken.value = newToken;

    return newToken;
  };

  const authCodeLogin = async ({ authCode, phoneNumber }) => {
    const newToken = await make({
      name: "loginCode",
      data: {
        token: authCode,
        phone: replaceNonWordChars(phoneNumber),
      },
    });

    authToken.value = newToken;

    return newToken;
  };

  const routeOnLoginSuccess = ({ redirectURI = null, query = null }) => {
    if (redirectURI) {
      const route = { path: decodeURI(redirectURI) };
      route.query = query ? JSON.parse(query) : "";

      return router.push(route);
    }

    if (!user.value) {
      return router.push({ name: constants.LOGIN_ROUTE });
    }

    return router.push({ name: "Selection" });
  };

  const loginWith = (loginMethod) => {
    return async (loginArgs, options = {}) => {
      // clear out any lingering cached values before authenticating
      clearCachedValues();

      try {
        if (options.fieldValidation) {
          const formIsValid = await options.fieldValidation();
          if (!formIsValid) {
            // There's an issue with field validation,
            // so don't try to login
            throw new Error("Field validation failed");
          }
        }

        await loginMethod(loginArgs);
        ls.set(constants.AUTH_TOKEN_KEY, authToken.value);
        createAuthInterceptor(authToken.value);

        await fetchUser();
        await fetchCompanyInvites(user.value);

        console.log("got user after login");
        routeOnLoginSuccess(options);
      } catch (err) {
        clearCachedValues();
        // TODO: handle this error
        throw err;
      }
    };
  };

  const logout = async ({ redirect, reason } = {}) => {
    await make({ name: "logout" });

    clearCachedValues();

    return router.push({
      name: constants.LOGIN_ROUTE,
      query: { redirect, reason },
    });
  };

  const sendAuthCode = async (phoneNumber) => {
    return make({
      name: "getAuthToken",
      data: {
        phone: replaceNonWordChars(phoneNumber),
      },
    });
  };

  const fetchUser = async () => {
    try {
      const { userDetails, minAppVersion: minAppVersions } = await make({ name: "getUserDetails" });
      user.value = userDetails;
      minAppVersion.value = minAppVersions.web;
      isCheckedForAuth.value = true;
      console.log("got user");
      return userDetails;
    } catch (_err) {
      if (_err?.mode === 'maintenance') {
        return router.push({
          name: constants.MAINTENANCE_PATH,
        });
      }
      // if this call fails, then force redirect
      clearCachedValues();

      isCheckedForAuth.value = true;

      setShowAppBanner(user.value, null, minAppVersion.value);

      return router.push({
        name: constants.LOGIN_ROUTE,
      });
    }
  };

  const signup = async (newUserDetails) => {
    return make({ name: "signup", data: newUserDetails });
  };

  const triggerPasswordReset = async (email) => {
    return make({ name: "triggerPasswordReset", data: { email } });
  };

  const resetPassword = async (data) => {
    const newToken = await make({
      name: "resetPassword",
      data,
    });

    authToken.value = newToken;

    return newToken;
  };

  const updateUser = async (updatedUserDetails) => {
    const userDetails = await make({ name: "updateUserDetails", data: updatedUserDetails });
    user.value = userDetails;

    setShowAppBanner(user.value, null, minAppVersion.value);

    return userDetails;
  };

  const clearCachedValues = () => {
    user.value = null;

    if (axios.interceptors?.request?.handlers?.length > 0) {
      axios.interceptors.request.handlers.forEach((_handler, idx) => {
        axios.interceptors.request.eject(idx);
      });
    }

    ls.remove(constants.AUTH_TOKEN_KEY);
    authToken.value = null;
  };

  return {
    acceptCompanyInvite,
    authToken,
    companyInvites,
    fetchCompanyInvites,
    fetchUser,
    ignoreCompanyInvite,
    isAuthenticating,
    isLoggedIn,
    loginWithAuthCode: loginWith(authCodeLogin),
    loginWithResetToken: loginWith(resetPassword),
    loginWithUsername: loginWith(defaultLogin),
    logout,
    sendAuthCode,
    signup,
    triggerPasswordReset,
    updateUser,
    user,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuth, import.meta.hot));
}

<script setup>
import { computed, onMounted, ref, onBeforeUnmount, watch } from "vue";
import PushkinLogo from "@/components/PushkinLogo";
import NavHamburger from "@/components/NavHamburger";
import router from "@/router";
import Avatar from "@/components/Avatar";
import Banner from "@/components/Banner";
import Loader from "@/components/Loader";
import Notifications from "@/components/Notifications";
import SettingsButton from "@/components/Settings";
import { useCompanies } from "@/stores/companies";
import { useCompanyBilling } from "@/stores/companies-billing";
import { useCompanyCheckins } from "@/stores/companies-checkins";
import { useTasks } from "@/stores/tasks";
import { useAuth } from "@/stores/auth";
import { useMemos } from "@/stores/memos";
import { useAppConfig, BANNER_TYPES } from "@/stores/app-config";
import { useChat } from "@/stores/chat";
import { storeToRefs } from "pinia";
import { useMemosResponse } from "@/stores/memos-response";
import {
  BeakerIcon,
  HomeIcon,
  UserCircleIcon,
  PencilSquareIcon,
  WrenchScrewdriverIcon,
  DocumentDuplicateIcon,
  ChatBubbleLeftRightIcon,
  ClipboardDocumentCheckIcon,
  Cog6ToothIcon,
  PaperAirplaneIcon,
  CubeTransparentIcon,
  DocumentIcon,
  RectangleGroupIcon
} from "@heroicons/vue/24/outline";

const appConfigStore = useAppConfig();
const authStore = useAuth();
const companyStore = useCompanies();
const billingStore = useCompanyBilling();
const checkinsStore = useCompanyCheckins();
const tasksStore = useTasks();
const memosStore = useMemosResponse();
const chatStore = useChat();

const { user } = storeToRefs(authStore);
const { selectedCompany, hasManyCompanies, companyFeatureFlag, isCompanyAdmin, isCompanyManager } =
  storeToRefs(companyStore);
const { billingSummary } = storeToRefs(billingStore);
const { disabledCompanyCheckins } = storeToRefs(checkinsStore);
const { openTasks, fetchPageParams } = storeToRefs(tasksStore);

const { unreadMemos } = storeToRefs(memosStore);
const { appBanner, showBanner } = storeToRefs(appConfigStore);
const { unreadChatMessages } = storeToRefs(chatStore);

const { logout } = authStore;

const menuOpened = ref(false);
const loading = ref(false);

const tabHeight = 44;
const logoWidth = 80;

const disabledAudiences = companyFeatureFlag.value("disable-audiences");
const disabledLocations = companyFeatureFlag.value("disable-locations");

const tabPosition = computed(() => {
  return selectedCompany.value ? `-${tabHeight}px` : "0px";
});

const nameLabel = computed(() => {
  return user.value?.name ? `Hi, ${user.value?.name}` : "Hi, User";
});

const avatarName = computed(() => {
  return user.value?.name ? user.value?.name : "User";
});

const mobileAction = (callback) => {
  menuOpened.value = false;
  callback();
};

const goToRoute = (name, query = {}) => {
  router.push({ name, query });
};

const mobileMenuOptions = computed(() => {
  return [
    {
      label: "Home",
      action: () => {
        goToRoute("Home");
      },
      icon: HomeIcon,
      tab: "home",
    },
    isCompanyManager.value && {
      label: "Team",
      action: () => {
        goToRoute("EmployeeList");
      },
      hasNotifications: false,
      icon: UserCircleIcon,
      tab: "team",
    },
    isCompanyManager.value && {
      label: "Memos",
      action: () => {
        goToRoute("MemoSendsList");
      },
      hasNotifications: unreadMemos.value > 0,
      icon: PencilSquareIcon,
      tab: "memo-category",
      hideForMobile: true,
    },
    isCompanyManager.value && {
      label: "Sent",
      action: () => {
        goToRoute("MemoSendsList");
      },
      hasNotifications: unreadMemos.value > 0,
      icon: PaperAirplaneIcon,
      tab: "memo-sends",
      isSubnav: true,
      mobilePrefix: "Memos",
    },
    isCompanyManager.value && {
      label: "Templates",
      action: () => {
        goToRoute("MemoTemplatesList");
      },
      hasNotifications: unreadMemos.value > 0,
      icon: CubeTransparentIcon,
      tab: "memo-templates",
      isSubnav: true,
      mobilePrefix: "Memos",
    },
    isCompanyManager.value && {
      label: "Packages",
      action: () => {
        goToRoute("MemoPackagesList");
      },
      hasNotifications: false,
      icon: DocumentIcon,
      tab: "memo-packages",
      isSubnav: true,
      mobilePrefix: "Memos",
    },
    isCompanyManager.value && {
      label: "Scheduler",
      action: () => {
        goToRoute("MemoScheduler");
      },
      hasNotifications: false,
      icon: DocumentIcon,
      tab: "memo-scheduler",
      isSubnav: true,
      mobilePrefix: "Memos",
    },
    isCompanyManager.value && {
      label: "Community",
      action: () => {
        goToRoute("Community");
      },
      hasNotifications: false,
      icon: RectangleGroupIcon,
      tab: "community",
      isSubnav: true,
      mobilePrefix: "Memos",
    },
    // isCompanyManager.value && {
    //   label: "Legacy",
    //   action: () => {
    //     goToRoute("MemosList");
    //   },
    //   hasNotifications: unreadMemos.value > 0,
    //   icon: DocumentIcon,
    //   tab: "memos",
    //   isSubnav: true,
    //   mobilePrefix: "Memos",
    // },
    companyFeatureFlag.value("tasks") && {
      label: "Tasks",
      action: () => {
        goToRoute("TasksList");
      },
      hasNotifications: openTasks.value > 0,
      flag: "tasks",
      icon: WrenchScrewdriverIcon,
      tab: "tasks",
    },
    {
      label: "Chats",
      action: () => {
        goToRoute("ChatGroupList");
      },
      hasNotifications: unreadChatMessages.value > 0,
      flag: "chat",
      icon: ChatBubbleLeftRightIcon,
      tab: "chat",
    },
    {
      label: "Files",
      action: () => {
        goToRoute("Files");
      },
      flag: "files",
      icon: DocumentDuplicateIcon,
      tab: "files",
    },
    isCompanyManager.value && {
      label: "Follow-ups",
      action: () => {
        goToRoute("FlaggedMessageList");
      },
      icon: ClipboardDocumentCheckIcon,
      tab: "flags",
    },
    isCompanyAdmin.value && {
      label: "Settings",
      action: () => {
        goToRoute("Settings");
      },
      icon: Cog6ToothIcon,
      tab: "settings",
    },
    // isCompanyAdmin.value && {
    //   label: "Manage Subscriptions",
    //   action: () => {
    //     goToRoute("Subscription");
    //   },
    // },
    // selectedCompany.value && {
    //   label: "Change Company",
    //   action: () => {
    //     goToRoute("Selection", { show: "yes" });
    //   },
    // },
    // {
    //   label: "Log Out",
    //   action: logout,
    // },
  ].filter(Boolean);
});

const checkFlagMobile = (item) => {
  if (!item.flag) {
    return true;
  }

  return companyFeatureFlag.value(item.flag);
};

const onBannerClick = () => {
  switch (appBanner.value.type) {
    case BANNER_TYPES.APP_VERSION:
      return window.location.reload();
    case BANNER_TYPES.SUBSCRIPTION_BANNER:
      return router.push({ name: "Subscription" });
    case BANNER_TYPES.INCOMPLETE_PROFILE:
    default:
      return router.push({ name: "Profile" });
  }
};

const version = process?.env?.VUE_APP_PACKAGE_VERSION
  ? `v${process.env.VUE_APP_PACKAGE_VERSION}`
  : "UNKNOWN VERSION";
</script>

<template>
  <div
    class="h-full w-full flex flex-col pt-12 lg:pt-0 overflow-hidden"
    id="app-scroll-container"
    v-if="selectedCompany?.id"
  >
    <Banner
      v-if="showBanner"
      :label="appBanner.label"
      cursor
      @click="onBannerClick"
      :alertLevel="appBanner.alertLevel"
    />

    <!-- MOBILE NAVIGATION -->
    <div
      class="
        nav-mobile
        lg:hidden
        bg-white
        flex flex-row
        items-center
        justify-center
        fixed
        w-full
        left-0
        right-0
        top-0
        shadow
      "
      style="z-index: 100"
    >
      <div class="mobile-logo-container opacity-30">
        <PushkinLogo :width="70" />
      </div>
      <div class="block fixed right-2 top-2.5 h-8 w-8 z-40">
        <NavHamburger v-model="menuOpened" />
      </div>
      <div class="nav-overlay" :class="{ opened: menuOpened }">
        <div class="mb-6 text-lg">Hi, {{ user?.name }}!</div>
        <div v-if="selectedCompany?.name" class="mb-8 text-sm relative">
          Logged in for {{ selectedCompany?.name }}
        </div>
        <template v-for="(opt, o) in mobileMenuOptions" :key="o">
          <div
            class="mb-4 text-maleficent underline cursor-pointer relative flex items-center"
            v-if="checkFlagMobile(opt) && !opt.hideForMobile"
            @click="mobileAction(opt.action)"
          >
            <span v-if="opt.mobilePrefix">{{ opt.mobilePrefix }}:&nbsp;</span>
            {{ opt.label }}
            <div v-if="opt.hasNotifications" class="bg-grimace w-3 h-3 ml-2 rounded-full"></div>
          </div>
        </template>
      </div>
    </div>

    <!-- MAIN NAVIGATION -->
    <div
      class="content-container flex-1 bg-white z-30 flex flex-col flex-grow relative h-full w-full"
    >
      <div class="nav hidden lg:block shadow relative z-20">
        <div class="flex items-center justify-between px-6 py-2">
          <div class="flex items-center">
            <router-link
              :to="{ name: 'Home', params: { company_id: selectedCompany.id } }"
              class="mr-4"
            >
              <PushkinLogo :width="logoWidth" />
            </router-link>
            <div class="mr-4 font-bold text-gray-300">X</div>
            <div v-if="selectedCompany" class="flex items-center">
              <div v-if="selectedCompany?.logo" class="mr-4 pr-4 border-r border-gray-200">
                <img :src="selectedCompany.logo" class="h-8" />
              </div>
              <div>
                <div v-if="selectedCompany.slogan" class="text-xs italic">
                  {{ selectedCompany.slogan }}
                </div>
              </div>
            </div>
          </div>
          <div class="right flex flex-row items-center">
            <div class="flex flex-col justify-center items-end mx-2">
              <div>{{ nameLabel }}</div>
              <div v-if="selectedCompany?.name" class="text-sm">
                Logged in for {{ selectedCompany.name }}
              </div>
            </div>
            <Notifications class="mx-2" />
            <Avatar class="avatar" :name="avatarName" :round="true" />
          </div>
        </div>
      </div>
      <div class="flex items-stretch flex-1 relative z-10 overflow-hidden">
        <div
          class="bg-gray-100 w-64 flex-shrink-0 nav hidden lg:flex h-full overflow-y-auto flex-col"
        >
          <div class="flex-grow flex flex-col relative pt-8" v-if="selectedCompany">
            <template v-for="(option, o) in mobileMenuOptions" :key="o">
              <div
                class="cursor-pointer flex items-center ml-6 justify-between relative mb-1"
                v-if="checkFlagMobile(option)"
                @click="mobileAction(option.action)"
              >
                <div
                  class="flex items-center p-2 flex-grow"
                  :class="{
                    'bg-blue bg-opacity-10 text-blue': $route?.meta?.tab === option.tab,
                    'text-maleficent': $route?.meta?.tab !== option.tab,
                    'pl-12 text-sm': option.isSubnav,
                  }"
                >
                  <component
                    :is="option.icon"
                    class="mr-4"
                    :class="[option.isSubnav ? 'h-4 w-4' : 'h-6 w-6']"
                    v-if="!option.isSubnav"
                  ></component>
                  <div>{{ option.label }}</div>
                  <div
                    v-if="option.hasNotifications"
                    class="bg-grimace w-3 h-3 ml-2 rounded-full"
                  ></div>
                </div>
                <div class="pl-4 h-full">
                  <div
                    class="w-4 h-full"
                    :class="{
                      'bg-blue bg-opacity-50': $route?.meta?.tab === option.tab,
                    }"
                  ></div>
                </div>
              </div>
            </template>
          </div>
          <div
            class="
              text-maleficent
              bg-white
              flex flex-col
              justify-between
              p-4
              border-t-2 border-gray-300
            "
          >
            <div class="mb-4">
              <a
                class="underline text-blue-dark"
                target="_blank"
                href="https://try.pushkinapp.com/terms-and-conditions/"
              >
                Terms </a
              >,
              <a
                href="https://try.pushkinapp.com/privacy-policy/"
                target="_blank"
                class="underline text-blue-dark"
              >
                Privacy </a
              >, &amp;
              <a class="underline text-blue-dark" href="mailto:support@pushkinapp.com">Contact</a>
              <!-- <a class="underline" href="https://pushkin.helpjuice.com/" target="_blank">Support</a> -->
            </div>
            <div class="text-xs">©2022 PushKin, Inc. <br />All rights reserved. {{ version }}</div>
          </div>
        </div>
        <div class="content p-4 md:py-6 md:px-8 flex-1 h-full overflow-y-auto">
          <router-view />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="h-full w-full flex flex-col items-center justify-center">
    <div>Loading company info ({{ companyId }})</div>
  </div>
  <Loader v-if="loading" />
</template>

<style lang="pcss" scoped>
.content {
  @apply z-20 lg:bg-white relative flex-1 flex-grow overflow-auto h-full;
}

.content-tabs {
  @apply z-10;
}

.tab-inner {
  transition: top 0.25s ease-in-out;
}

.tab {
  @apply text-center cursor-pointer flex items-center ml-4 mb-2 p-2;
}

.tab.tab-selected {
  @apply bg-blue bg-opacity-10;
}

.tab-inner {
  @apply transform -translate-x-14 lg:translate-x-0;
}

.nav-mobile {
  height: 50px;
}

.nav-mobile .nav-overlay {
  transition: transform 0.25s ease-in-out;

  @apply fixed w-screen h-screen top-0 bottom-0 left-0 right-0 bg-white bg-opacity-90 z-30 text-blue flex flex-col justify-center items-center transform translate-x-full;
}

.nav-mobile .nav-overlay.opened {
  @apply translate-x-0;
}

.notification-dot::after {
  background-color: #d11f9f;
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  z-index: 100000;
  border-radius: 12px;
  left: -5px;
  top: -5px;
}

.subnav {
  @apply hidden lg:flex mt-4 mb-12 flex-row;
}

.subnav-item {
  @apply mr-8 text-lg;
}

.subnav-item.selected {
  @apply border-b-4 border-gray-dark;
}
</style>

export default {
  HOME_ROUTE: "Home",
  LOGIN_ROUTE: "Login",
  CFA_INTEGRATION_ROUTE: "CFAIntegration",
  CFA_LOGIN_ROUTE: "CFALogin",
  CFA_CONFIRM_ROUTE: "CFAConfirm",
  MAINTENANCE_ROUTE: "Maintenance",
  MAINTENANCE_PATH:  '/maintenance',
  AUTH_TOKEN_KEY: "pushkin-token",
  OKTA_AUTH_ACCESS_TOKEN: "okta-access-token",
  OKTA_AUTH_REFRESH_TOKEN: "okta-refresh-token",
  API_URL: process.env.VUE_APP_API_URL,
  TIMEZONE_OPTIONS: [
    {
      label: "New York",
      value: "America/New_York",
    },
    {
      label: "Chicago",
      value: "America/Chicago",
    },
    {
      label: "Denver",
      value: "America/Denver",
    },
    {
      label: "Phoenix",
      value: "America/Phoenix",
    },
    {
      label: "Los Angeles",
      value: "America/Los_Angeles",
    },
    {
      label: "Anchorage",
      value: "America/Anchorage",
    },
    {
      label: "Honolulu",
      value: "America/Honolulu",
    },
  ],
  HTML_TEXT_INPUT_TYPES: ["date", "email", "number", "password", "search", "tel", "text", "url"],
  CUSTOM_TEXT_INPUT_TYPES: ["auth-code"],
};

import { ref, computed, watch, onMounted, reactive } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { make } from "@/utils/request";
import { mapEntityToSelect, replaceItemInStore, compareEntityByName } from "@/utils/helpers";
import Employee from "@/classes/Employee";
import { useToasts } from "./toasts";

import { useCompanies } from "./companies";

export const useCompanyEmployees = defineStore("companies-employees", () => {
  const companyStore = useCompanies();
  const toastsStore = useToasts();

  const { selectedCompany, isCompanyManagement, company } = storeToRefs(companyStore);

  const companyEmployees = ref([]);
  const selectedEmployee = ref(null);
  const isFetching = ref(false);

  const companyEmployeesSelectOptions = computed(() => {
    if (!company.value?.employees?.length) {
      return [];
    }
    return company.value?.employees?.map(mapEntityToSelect("display_name", "id"));
  });

  const fetchPageParams = reactive({
    currentPage: null,
    totalPages: null,
    sortBy: null,
    sortDirection: null,
    searchTerms: null,
    audienceId: "All",
    locationId: "All",
    seeAll: null,
  });

  const fetchFilters = reactive({
    audiences: [],
    completedAt: "default",
    locations: [],
  });

  const employeeLocations = computed(() => {
    if (!selectedEmployee?.value?.id) {
      return [];
    }

    return selectedEmployee.value.locations || [];
  });

  const companyEmployeeTypes = computed(() => {
    if (!selectedCompany?.value?.employee_types) {
      return [];
    }

    return selectedCompany.value.employee_types.map((type) => ({ label: type, value: type }));
  });

  const employeeLocationsSelectOptions = computed(() => {
    if (!employeeLocations?.value?.length) {
      return [];
    }

    return employeeLocations.value.map(mapEntityToSelect("name", "id"));
  });

  const fetchEmployees = async (active = "yes") => {
    // if admin, fetch all employee details otherwise use employee details from company object
    if (isCompanyManagement.value) {
      const response = await make({
        name: "settingsEmployees",
        params: {
          company_id: selectedCompany.value.id,
          active,
        },
      });
      const employees = response.map((employee) => new Employee(employee));
      companyEmployees.value = employees;
      return response;
    } else {
      const employees = company.value.employees.map((employee) => new Employee(employee));
      companyEmployees.value = employees;
      return Promise.resolve(company.value.employees);
    }
  };

  const fetchPaginatedEmployees = async (active = "yes") => {
    const pageParams = {
      page: fetchPageParams.currentPage,
      terms: fetchPageParams.searchTerms,
      locations: fetchPageParams.locationId === "All" ? null : fetchPageParams.locationId,
      audiences: fetchPageParams.audienceId === "All" ? null : fetchPageParams.audienceId,
      sort_by: fetchPageParams.sortBy,
      sort_direction: fetchPageParams.sortDirection,
      see_all: fetchPageParams.seeAll,
      active,
    };

    const response = await make({
      name: "getEmployees",
      params: {
        company_id: selectedCompany.value.id,
        ...pageParams,
      },
    });

    if (response) {
      fetchPageParams.currentPage = response.current_page;
      fetchPageParams.totalPages = response.total_pages;
      fetchPageParams.sortBy = response.sort_by;
      fetchPageParams.sortDirection = response.sort_direction;
      if (response?.employees?.length > 0) {
        const employees = response.employees.map((employee) => new Employee(employee));
        return employees;
      }

      return response.employees;
    }
  };

  const fetchInactiveEmployees = async () => {
    const response = await make({
      name: "getEmployees",
      params: {
        company_id: selectedCompany.value.id,
        see_all: "true",
        active: "no",
      },
    });

    if (response?.employees?.length > 0) {
      const employees = response.employees.map((employee) => new Employee(employee));
      return employees;
    }

    return [];
  };

  const updateEmployees = async (employeeDetails) => {
    const employees = await make({
      name: "bulkUpdateEmployees",
      data: {
        ...employeeDetails,
        company_id: selectedCompany.value.id,
      },
    });

    await companyStore.refreshSelectedCompany();

    return employees;
  };

  const fetchEmployee = async (employeeId) => {
    const employee = await make({
      name: "getEmployee",
      params: {
        company_id: selectedCompany.value.id,
        id: employeeId,
      },
    });

    const newEmployee = new Employee(employee);

    const isInStore = companyEmployees.value.find(
      (companyEmployee) => companyEmployee.id === newEmployee.id
    );

    if (isInStore) {
      replaceItemInStore(newEmployee, companyEmployees.value);
      return newEmployee;
    }

    companyEmployees.value.push(newEmployee);
    return newEmployee;
  };

  const fetchEmployeeMemoSummary = async (employeeId) => {
    const response = await make({
      name: "getEmployeeMemoSummary",
      params: {
        company_id: selectedCompany.value.id,
        id: employeeId,
      },
    });

    return response;
  };

  const fetchCSVData = async (employeeId) => {
    try {
      const response = await make({
        name: "getCSVData",
        params: {
          company_id: selectedCompany.value.id,
          id: employeeId,
        },
      });
      if (response.read_memos.length || response.unread_memos.length) {
        return response;
      } else {
        toastsStore.addToast({
          title: "",
          message: "No record found to download",
          variant: "error",
        });
      }
      return false;
    } catch (error) {
      toastsStore.addToast({
        title: "Error",
        message: error,
        variant: "error",
      });
    }
    return false;
  };

  const fetchSelfEmployee = async () => {
    const employee = await make({
      name: "getSelfEmployee",
      params: {
        company_id: selectedCompany.value.id,
      },
    });

    const selfEmployee = new Employee(employee);
    selectedEmployee.value = selfEmployee;

    return selfEmployee;
  };

  const createEmployee = async (employeeDetails) => {
    const employee = await make({
      name: "createEmployee",
      data: {
        company_id: selectedCompany.value.id,
        ...employeeDetails,
      },
    });

    const newEmployee = employee ? new Employee(employee) : null;

    if (newEmployee && Array.isArray(selectedCompany?.value?.employees)) {
      selectedCompany.value.employees.push(newEmployee);
    }

    if (newEmployee) {
      companyEmployees.value.push(newEmployee);
      companyEmployees.value.sort(compareEntityByName);
    }

    return newEmployee;
  };

  const assignEmployee = async (employeeId) => {
    const response = await make({
      name: "requestEmployee",
      params: {
        company_id: selectedCompany.value.id,
        id: employeeId,
      },
    });

    return response;
  };

  const getSignature = async (imageUrl) => {
    const response = await make({
      name: 'getSignature',
      params: {
        company_id: selectedCompany.value.id,
        file_path: imageUrl
      },
      options: {
        responseType: "blob",
      },
    });
    return response;
  }

  const unassignEmployee = async (employeeId) => {
    const response = await make({
      name: "unrequestEmployee",
      params: {
        company_id: selectedCompany.value.id,
        id: employeeId,
      },
    });

    return response;
  };

  const updateEmployee = async (employeeDetails) => {
    const employee = await make({
      name: "editEmployee",
      data: {
        company_id: selectedCompany.value.id,
        ...employeeDetails,
      },
    });

    const updatedEmployee = employee ? new Employee(employee) : null;

    if (updatedEmployee) {
      replaceItemInStore(updatedEmployee, companyEmployees?.value);
    }

    await companyStore.refreshSelectedCompany();

    return updatedEmployee;
  };

  const deleteEmployee = async (employeeId) => {
    const response = await make({
      name: "deleteEmployee",
      data: {
        company_id: selectedCompany.value.id,
        id: employeeId,
      },
    });

    if (response.successes.includes("employee_deleted")) {
      companyEmployees.value = companyEmployees.value.filter(
        (companyEmployee) => companyEmployee.id.toString() !== employeeId.toString()
      );
    }
    await companyStore.refreshSelectedCompany();

    return companyEmployees.value;
  };

  const resetFetchParams = () => {
    fetchPageParams.currentPage = null;
    fetchPageParams.totalPages = null;
    fetchPageParams.sortBy = null;
    fetchPageParams.sortDirection = null;
    fetchPageParams.terms = null;
  };

  const init = async () => {
    if (isFetching.value === true) {
      return;
    }

    isFetching.value = true;

    if (selectedCompany?.value?.id) {
      await fetchEmployees();
    }

    isFetching.value = false;
  };

  return {
    assignEmployee,
    companyEmployees,
    companyEmployeesSelectOptions,
    companyEmployeeTypes,
    createEmployee,
    deleteEmployee,
    employeeLocationsSelectOptions,
    fetchSelfEmployee,
    fetchEmployee,
    fetchEmployees,
    fetchPaginatedEmployees,
    fetchFilters,
    fetchPageParams,
    init,
    selectedEmployee,
    unassignEmployee,
    updateEmployee,
    updateEmployees,
    fetchInactiveEmployees,
    fetchEmployeeMemoSummary,
    fetchCSVData,
    getSignature,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCompanyEmployees, import.meta.hot));
}
